body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

@font-face {
    font-family: nib-pro;
    src: url('/nib-semibold-pro.woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: nib-pro;
    src: url('/nib-semibold-italic-pro.woff2');
    font-weight: 600;
    font-style: italic;
}

:root {
    font-size: 16px;
}

#root {
    height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.app {
    height: 100%;
    width: 100vw;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.app--not-ready {
    background-color: #ff0;
}

.app--error {
    background-color: #f00;
}

.app--printing {
    background-color: #0f0;
}

.header {
    flex: 0 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid black;
    padding: 10px;
    gap: 0.5rem;
}

.header-brand {
    flex: 0 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.header-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-left: auto;
}

.modal__actions > * + * {
    margin-left: 10px;
}

.logo {
    height: 30px;
    margin-right: 20px;
}

.info {
    margin-left: auto;
    margin-right: 20px;
}

.button {
    font-weight: bold;
    font-size: 1rem;
    padding: 5px;
}

.content {
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
}

.text-wrapper {
    margin: auto;
    text-align: center;
    padding: 20px;
}

.title {
    font-size: 72px;
    font-weight: bold;
}

.title--error {
    color: white;
}

.subtitle {
    font-size: 44px;
}

.subtitle--error {
    color: white;
}

.hidden-iframe {
    opacity: 0;
    pointer-events: none;
    width: 300px;
    height: 300px;
    position: absolute;
    top: 0;
    bottom: 0;
}

.hidden-input {
    opacity: 0;
    pointer-events: none;
    width: 1px;
    height: 1px;
}

.error {
    color: red;
}

.login-form {
    display: flex;
    flex-direction: column;
}

.login-form__label {
    font-size: 16px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.login-form__input {
    font-size: 16px;
    padding: 10px;
    margin-left: auto;
    width: 300px;
}

.login-form__button {
    align-self: flex-end;
}

.bulk {
    width: 700px;
    max-width: 100%;
    margin: 0 auto;
    padding: 1rem;
}

th,
td {
    border: 1px solid;
    margin: 0;
    padding: 8px;
}

input[type='number'] {
    font-size: 16px;
}

.table-container {
    margin: 1rem;
}

#file-upload {
    border: 1px solid;
    padding: 1rem;
    margin: 1rem;
}

.loading-message {
    padding: 1rem;
    border: 1px solid;
    font-size: 32px;
    font-weight: bold;
}

/* this ensures that the canvases won't go onto two pages */
.canvas-container {
    /* 1.8 times the size of the gift-content, ask rico for context */
    width: 7.2in;
    height: 9in;
    max-height: 9in;
    overflow: hidden;
    page-break-inside: avoid;
    margin-left: 22px;
}

.print-canvas {
    page-break-inside: avoid;
    display: block;
}

.gift-content {
    color: #224229;
    font-family: nib-pro, serif;
    text-align: center;
    width: 4in;
    height: 5in;
    display: flex;
    flex-direction: column;
    align-items: center;
    page-break-inside: avoid;
    margin-left: -22px;
}

.gift-title {
    font-size: 36px;
    margin: 25px 0;
}

.gift-em {
    font-style: italic;
    text-decoration: underline;
}

.gift-img {
    width: 200px;
    height: 75px;
    object-fit: contain;
    margin-bottom: 30px;
}

.gift-note {
    width: 275px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.1em;
    padding: 22px 0;
    margin-bottom: auto;
    white-space: pre-line;
}

.modal__container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
}

.modal__backdrop {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal {
    margin: auto;
    width: 480px;
    max-width: 100%;
    z-index: 1;
    background-color: yellow;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.75);
    padding: 10px;
}

.modal__title {
    font-size: 24px;
    margin: 0;
}

.modal__actions {
    display: flex;
    justify-content: flex-end;
}

.gift-note-printer {
    width: 700px;
    max-width: 100%;
    margin: 0 auto;
    padding: 1rem;
}

.gift-note-text-area {
    width: 100%;
    display: block;
    margin-bottom: 1rem;
    resize: vertical;
}

.gift-note-label {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    display: block;
}
